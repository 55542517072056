@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon/icomoon.eot?srf3rx");
  src: url("../fonts/icomoon/icomoon.eot?srf3rx#iefix") format("embedded-opentype"), url("../fonts/icomoon/icomoon.ttf?srf3rx") format("truetype"), url("../fonts/icomoon/icomoon.woff?srf3rx") format("woff"), url("../fonts/icomoon/icomoon.svg?srf3rx#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* =======================================================
*
* 	Template Style 
*
* ======================================================= */
body {
  font-family: "Josefin Slab", Arial, serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
  /*color: #828282;*/
  color: black;
}

#page {
  position: relative;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.offcanvas #page {
  overflow: hidden;
  position: absolute;
}
.offcanvas #page:after {
  -webkit-transition: 2s;
  -o-transition: 2s;
  transition: 2s;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.7);
  content: "";
}

a {
  color: #1d2f3b;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
a:hover, a:active, a:focus {
  color: #1d2f3b;
  outline: none;
  text-decoration: none;
}

p {
  margin-bottom: 20px;
  font-size: 18px;
}

h1, h2, h3, h4, h5, h6, figure {
  /*color: #000;*/
  color: black;
  font-family: "Josefin Slab", Arial, serif;
  font-weight: 400;
  margin: 0 0 20px 0;
}

::-webkit-selection {
  color: #fff;
  background: #1d2f3b;
}

::-moz-selection {
  color: #fff;
  background: #1d2f3b;
}

::selection {
  color: #fff;
  background: #1d2f3b;
}

.fh5co-nav .top-menu {
  padding: 12px 0;
  width: 100%;
  float: left;
  position: relative;
}
.fh5co-nav .top-menu:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  background: #fbf2eb;
}
.fh5co-nav #fh5co-logo {
  font-size: 50px;
  margin: 0;
  font-weight: 700;
  font-family: "Sacramento", cursive;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  display: table;
  position: relative;
}
.fh5co-nav #fh5co-logo:before {
  position: absolute;
  top: -10px;
  bottom: 0;
  left: -10px;
  right: 0;
  width: 150px;
  height: 150px;
  background: #fbf2eb;
  content: "";
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.fh5co-nav #fh5co-logo h1 {
  font-family: "Sacramento", cursive !important;
  font-size: 50px;
  margin-bottom: 0;
  width: inherit;
  width: 150px;
  height: 150px;
  display: table-cell;
  vertical-align: middle;
  line-height: 32px;
  position: relative;
  border: 1px solid black;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.fh5co-nav #fh5co-logo a span {
  color: #1d2f3b;
}
.fh5co-nav #fh5co-logo a small {
  font-family: "Josefin Slab", Arial, serif;
  font-size: 12px;
}
.fh5co-nav a {
  padding: 5px 10px;
  color: #000;
}
@media screen and (max-width: 768px) {
  .fh5co-nav .menu-1 {
    display: none;
  }
}
.fh5co-nav .menu-2 {
  padding: 2em 0;
}
.fh5co-nav ul {
  padding: 0;
  margin: 5px 0 0 0;
}
.fh5co-nav ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline;
  font-weight: 700;
}
.fh5co-nav ul li a {
  font-size: 14px;
  padding: 30px 15px;
  color: rgba(0, 0, 0, 0.7);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.fh5co-nav ul li a:hover, .fh5co-nav ul li a:focus, .fh5co-nav ul li a:active {
  color: black;
}
.fh5co-nav ul li.has-dropdown {
  position: relative;
}
.fh5co-nav ul li.has-dropdown .dropdown {
  width: 140px;
  -webkit-box-shadow: 0px 14px 33px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 14px 33px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 14px 33px -9px rgba(0, 0, 0, 0.75);
  z-index: 1002;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 30px;
  left: 0;
  text-align: left;
  background: #000;
  padding: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
}
.fh5co-nav ul li.has-dropdown .dropdown:before {
  bottom: 100%;
  left: 40px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #000;
  border-width: 8px;
  margin-left: -8px;
}
.fh5co-nav ul li.has-dropdown .dropdown li {
  display: block;
  margin-bottom: 7px;
}
.fh5co-nav ul li.has-dropdown .dropdown li:last-child {
  margin-bottom: 0;
}
.fh5co-nav ul li.has-dropdown .dropdown li a {
  padding: 2px 0;
  display: block;
  color: #999999;
  line-height: 1.2;
  text-transform: none;
  font-size: 13px;
  letter-spacing: 0;
}
.fh5co-nav ul li.has-dropdown .dropdown li a:hover {
  color: #fff;
}
.fh5co-nav ul li.has-dropdown:hover a, .fh5co-nav ul li.has-dropdown:focus a {
  color: #000;
}
.fh5co-nav ul li.btn-cta a {
  padding: 30px 0px !important;
  color: #fff;
}
.fh5co-nav ul li.btn-cta a span {
  background: #1d2f3b;
  padding: 4px 10px;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  border-radius: 100px;
}
.fh5co-nav ul li.btn-cta a:hover span {
  -webkit-box-shadow: 0px 14px 20px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 14px 20px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 14px 20px -9px rgba(0, 0, 0, 0.75);
}
.fh5co-nav ul li.active > a {
  color: #000 !important;
  position: relative;
}
.fh5co-nav ul li.active > a:before {
  position: absolute;
  bottom: 11px;
  left: 0;
  right: 0;
  content: '';
  width: 40%;
  height: 1px;
  margin: 0 auto;
  /*background: #000;*/
  background: firebrick;
}
.fh5co-nav .fh5co-social-icons {
  text-align: right;
}
@media screen and (max-width: 768px) {
  .fh5co-nav .fh5co-social-icons {
    text-align: left;
  }
}
.fh5co-nav .fh5co-social-icons li a {
  padding: inherit;
  padding-left: 5px;
  padding-right: 5px;
}
.fh5co-nav .fh5co-social-icons li a i {
  font-size: 18px;
  color: #000;
}

#fh5co-counter,
.fh5co-bg,
.product,
.featured,
.insta {
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  position: relative;
}

.fh5co-video {
  overflow: hidden;
}
@media screen and (max-width: 992px) {
  .fh5co-video {
    height: 450px;
  }
}
.fh5co-video a {
  z-index: 1001;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -45px;
  margin-left: -45px;
  width: 90px;
  height: 90px;
  display: table;
  text-align: center;
  background: #fff;
  -webkit-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  -ms-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  -o-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.fh5co-video a i {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-size: 40px;
}
.fh5co-video .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.fh5co-video:hover .overlay {
  background: rgba(0, 0, 0, 0.7);
}
.fh5co-video:hover a {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

#fh5co-hero {
  min-height: 600px;
  background: #fff url(../images/loader.gif) no-repeat center center;
}
@media screen and (max-width: 480px) {
  #fh5co-hero {
    min-height: 400px;
  }
}
#fh5co-hero .btn {
  font-size: 24px;
}
#fh5co-hero .btn.btn-primary {
  padding: 14px 30px !important;
}
#fh5co-hero .flexslider {
  border: none;
  z-index: 1;
  margin-bottom: 0;
}
#fh5co-hero .flexslider .slides {
  position: relative;
  overflow: hidden;
}
#fh5co-hero .flexslider .slides li {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 600px;
  position: relative;
}
@media screen and (max-width: 480px) {
  #fh5co-hero .flexslider .slides li {
    min-height: 400px;
  }
}
#fh5co-hero .flexslider .slides li:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
}
#fh5co-hero .flexslider .slides .holder:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
}
#fh5co-hero .flexslider .flex-control-nav {
  bottom: 80px;
  z-index: 1000;
  right: 20px;
  float: right;
  width: auto;
}
#fh5co-hero .flexslider .flex-control-nav li {
  display: block;
  margin-bottom: 10px;
}
#fh5co-hero .flexslider .flex-control-nav li a {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: none;
  width: 12px;
  height: 12px;
  cursor: pointer;
}
#fh5co-hero .flexslider .flex-control-nav li a.flex-active {
  cursor: pointer;
  background: transparent;
  border: 1px solid #fff;
}
#fh5co-hero .flexslider .flex-direction-nav {
  display: none;
}
#fh5co-hero .flexslider .slider-text {
  display: table;
  opacity: 0;
  min-height: 600px;
  z-index: 9;
}
@media screen and (max-width: 480px) {
  #fh5co-hero .flexslider .slider-text {
    min-height: 400px;
  }
}
#fh5co-hero .flexslider .slider-text > .slider-text-inner {
  display: table-cell;
  vertical-align: middle;
  height: 600px;
}
@media screen and (max-width: 480px) {
  #fh5co-hero .flexslider .slider-text > .slider-text-inner {
    height: 400px;
  }
}
#fh5co-hero .flexslider .slider-text > .slider-text-inner h1, #fh5co-hero .flexslider .slider-text > .slider-text-inner h2 {
  margin: 0;
  padding: 0;
  color: white;
}
#fh5co-hero .flexslider .slider-text > .slider-text-inner h1 {
  margin-bottom: 20px;
  font-size: 40px;
  line-height: 1.3;
  font-weight: 400;
}
@media screen and (max-width: 768px) {
  #fh5co-hero .flexslider .slider-text > .slider-text-inner h1 {
    font-size: 30px;
  }
}
#fh5co-hero .flexslider .slider-text > .slider-text-inner h2 {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 30px;
}
#fh5co-hero .flexslider .slider-text > .slider-text-inner h2 a {
  color: rgba(255, 255, 255, 0.6);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
#fh5co-hero .flexslider .slider-text > .slider-text-inner .heading-section {
  font-size: 50px;
}
@media screen and (max-width: 768px) {
  #fh5co-hero .flexslider .slider-text > .slider-text-inner .heading-section {
    font-size: 30px;
  }
}
#fh5co-hero .flexslider .slider-text > .slider-text-inner .ct a {
  color: #fff;
}
#fh5co-hero .flexslider .slider-text > .slider-text-inner .form-inline .form-group {
  width: 100% !important;
  margin-bottom: 10px;
}
#fh5co-hero .flexslider .slider-text > .slider-text-inner .form-inline .form-group .form-control {
  width: 100%;
  background: #fff;
  border: none;
}
#fh5co-hero .flexslider .slider-text > .slider-text-inner .fh5co-lead {
  font-size: 20px;
  color: #fff;
}
#fh5co-hero .flexslider .slider-text > .slider-text-inner .fh5co-lead .icon-heart {
  color: #d9534f;
}
#fh5co-hero .featured {
  width: 100%;
  display: table;
  height: 265px;
  position: relative;
}
#fh5co-hero .featured:first-child {
  margin-bottom: 30px;
}
#fh5co-hero .featured .desc {
  display: table-cell;
  vertical-align: middle;
  height: 285px;
  padding: 40px;
  background: rgba(255, 255, 255, 0.8);
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
#fh5co-hero .featured .desc span {
  color: rgba(0, 0, 0, 0.5);
}
#fh5co-hero .featured .desc .date {
  display: block;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  #fh5co-hero .featured .desc {
    opacity: 1;
  }
}
#fh5co-hero .featured:hover .desc {
  opacity: 1;
}

.fh5co-bg-section {
  background: rgba(29, 47, 59, 0.03);
}

#fh5co-about,
#fh5co-content,
#fh5co-started,
#fh5co-blog,
#fh5co-blog-popular,
#fh5co-contact,
#fh5co-footer {
  padding: 7em 0;
  clear: both;
}
@media screen and (max-width: 768px) {
  #fh5co-about,
  #fh5co-content,
  #fh5co-started,
  #fh5co-blog,
  #fh5co-blog-popular,
  #fh5co-contact,
  #fh5co-footer {
    padding: 3em 0;
  }
}

.fh5co-no-pd-top {
  padding-top: 1.5em !important;
}

#fh5co-blog-popular {
  padding-bottom: 0 !important;
}
#fh5co-blog-popular .fh5co-blog {
  margin-bottom: 0;
}

.fh5co-social-icons {
  margin: 0;
  padding: 0;
}
.fh5co-social-icons li {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.fh5co-social-icons li a {
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  color: #1d2f3b;
  padding-left: 10px;
  padding-right: 10px;
}
.fh5co-social-icons li a i {
  font-size: 20px;
}

.fh5co-heading {
  margin-bottom: 5em;
  position: relative;
}
.fh5co-heading:after {
  position: absolute;
  top: 28px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  content: '';
  background: black;
  z-index: -1;
}
.fh5co-heading h2 {
  font-size: 40px;
  margin-bottom: 20px;
  line-height: 1.5;
  color: #000;
  font-family: "Sacramento", cursive;
}
.fh5co-heading h2 span {
  padding: 20px 30px;
  border: 1px solid #000;
  z-index: 8;
  position: relative;
}
.fh5co-heading h2 span:after {
  position: absolute;
  top: -10px;
  bottom: 10px;
  left: -10px;
  right: 10px;
  content: '';
  background: #fbf2eb;
  z-index: -1;
}
.fh5co-heading p {
  font-size: 18px;
  line-height: 1.5;
  color: #828282;
}
.fh5co-heading.fh5co-heading-sidebar {
  margin-bottom: 2em;
}
.fh5co-heading.fh5co-heading-sidebar:before {
  top: 20px;
}
.fh5co-heading.fh5co-heading-sidebar h2 {
  font-size: 30px;
}
.fh5co-heading.fh5co-heading-sidebar h2 span {
  padding: 10px 20px;
  border: 1px solid #000;
  z-index: 8;
  position: relative;
}

.fh5co-blog {
  margin-bottom: 3em;
  width: 100%;
  float: left;
}
.fh5co-blog > a {
  display: block;
  position: relative;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.fh5co-blog > a img {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .fh5co-blog {
    width: 100%;
  }
}
.fh5co-blog .title {
  margin-bottom: 40px;
}
.fh5co-blog .title.title-pin h3 {
  font-size: 34px;
}
.fh5co-blog .title.title-pin h3 a {
  color: #000;
}
.fh5co-blog .title h3 {
  font-size: 24px;
}
.fh5co-blog .title h3 a {
  color: #000;
}
.fh5co-blog .title .posted-on {
  display: inline-block;
  margin-bottom: 15px;
  position: relative;
  font-weight: 700;
  font-size: 15px;
}
.fh5co-blog .title .category {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 13px;
  font-weight: 700;
  position: relative;
  margin-bottom: 10px;
}
.fh5co-blog .title .category:before {
  position: absolute;
  top: 5px;
  left: -120px;
  right: 0;
  bottom: 0;
  content: '';
  width: 100px;
  height: 1px;
  background: rgba(0, 0, 0, 0.08);
}
.fh5co-blog .title .category:after {
  position: absolute;
  top: 5px;
  right: -120px;
  bottom: 0;
  content: '';
  width: 100px;
  height: 1px;
  background: rgba(0, 0, 0, 0.09);
}
.fh5co-blog .blog-text {
  margin-bottom: 30px;
  position: relative;
  width: 100%;
  padding: 30px;
  float: left;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.06);
}
.fh5co-blog .blog-text span {
  display: inline-block;
  margin-bottom: 20px;
}
.fh5co-blog .blog-text span.comment {
  float: right;
}
.fh5co-blog .blog-text span.comment a {
  color: rgba(0, 0, 0, 0.3);
}
.fh5co-blog .blog-text span.comment a i {
  color: #1d2f3b;
  padding-left: 7px;
}
.fh5co-blog .blog-text h3 {
  font-size: 20px;
  margin-bottom: 10px;
  line-height: 1.5;
  font-weight: 600;
}
.fh5co-blog .blog-text h3 a {
  color: black;
}
.fh5co-blog .blog-text .fh5co-social-icons {
  margin-top: 30px;
}
.fh5co-blog .blog-text .fh5co-social-icons li a i {
  font-size: 30px;
}
.fh5co-blog .blog-text .fh5co-social-icons li:first-child {
  display: block;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 13px;
}

.insta {
  height: 350px;
}

#sidebar .side {
  margin-bottom: 6em;
  width: 100%;
  float: left;
}
#sidebar .blog-entry {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  float: left;
  padding: 20px 0;
}
#sidebar .blog-entry > a {
  width: 100%;
  float: left;
  position: relative;
}
#sidebar .blog-entry > a img {
  float: left;
  width: 90px;
  height: 90px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
#sidebar .blog-entry > a .desc {
  padding-top: 10px;
  padding-left: 110px;
}
#sidebar .blog-entry > a .desc .date {
  color: rgba(0, 0, 0, 0.4);
}
#sidebar .blog-entry > a .desc h3 {
  font-size: 20px;
}
#sidebar .category {
  margin: 0;
  padding: 0;
}
#sidebar .category li {
  list-style: none;
  padding: 7px 0;
}
#sidebar .category li a {
  color: #000;
  position: relative;
  padding-left: 20px;
}
#sidebar .category li a i {
  position: absolute;
  top: 0;
  left: 0;
}
#sidebar .insta {
  height: 250px;
}

.fh5co-staff {
  text-align: center;
  margin-bottom: 30px;
}
.fh5co-staff img {
  width: 140px;
  height: 140px;
  margin: 0 auto;
  margin-bottom: 20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.fh5co-staff h3 {
  font-size: 24px;
  margin-bottom: 5px;
}
.fh5co-staff p {
  margin-bottom: 30px;
}
.fh5co-staff .role {
  color: gray;
  margin-bottom: 30px;
  font-weight: normal;
  display: block;
}

#fh5co-footer {
  background: #efefef;
}
#fh5co-footer .fh5co-footer-links {
  padding: 0;
  margin: 0;
}
@media screen and (max-width: 768px) {
  #fh5co-footer .fh5co-footer-links {
    margin-bottom: 30px;
  }
}
#fh5co-footer .fh5co-footer-links li {
  padding: 0;
  margin: 0;
  list-style: none;
}
#fh5co-footer .fh5co-footer-links li a {
  /*color: rgba(0, 0, 0, 0.5);*/
  color: black;
  text-decoration: none;
}
#fh5co-footer .fh5co-footer-links li a:hover {
  text-decoration: underline;
}
#fh5co-footer .fh5co-widget {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  #fh5co-footer .fh5co-widget {
    text-align: left;
  }
}
#fh5co-footer .fh5co-widget h3 {
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
#fh5co-footer .copyright .block {
  display: block;
}

#map {
  width: 100%;
  height: 500px;
  position: relative;
}
@media screen and (max-width: 768px) {
  #map {
    height: 200px;
  }
}

#fh5co-offcanvas {
  position: absolute;
  z-index: 1901;
  width: 270px;
  /*background: black;*/
  background: #fbf2eb;
  top: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 75px 40px 40px 40px;
  overflow-y: auto;
  display: none;
  -moz-transform: translateX(270px);
  -webkit-transform: translateX(270px);
  -ms-transform: translateX(270px);
  -o-transform: translateX(270px);
  transform: translateX(270px);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
@media screen and (max-width: 768px) {
  #fh5co-offcanvas {
    display: block;
  }
}
.offcanvas #fh5co-offcanvas {
  -moz-transform: translateX(0px);
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
  transform: translateX(0px);
}
#fh5co-offcanvas a {
  /*color: rgba(255, 255, 255, 0.5);*/
  color: darkslategray;
}
#fh5co-offcanvas a:hover {
  /*color: rgba(255, 255, 255, 0.8);*/
  color: black;
  font-weight: bold;
}
#fh5co-offcanvas ul {
  padding: 0;
  margin: 0;
}
#fh5co-offcanvas ul li {
  padding: 0;
  margin: 0;
  list-style: none;
}
#fh5co-offcanvas ul li > ul {
  padding-left: 20px;
  display: none;
}
#fh5co-offcanvas ul li.offcanvas-has-dropdown > a {
  display: block;
  position: relative;
}
#fh5co-offcanvas ul li.offcanvas-has-dropdown > a:after {
  position: absolute;
  right: 0px;
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e921";
  font-size: 20px;
  color: rgba(255, 255, 255, 0.2);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
#fh5co-offcanvas ul li.offcanvas-has-dropdown.active a:after {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.uppercase {
  font-size: 14px;
  color: #000;
  margin-bottom: 10px;
  font-weight: 700;
  text-transform: uppercase;
}

.gototop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.gototop.active {
  opacity: 1;
  visibility: visible;
}
.gototop a {
  width: 50px;
  height: 50px;
  display: table;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
.gototop a i {
  height: 50px;
  display: table-cell;
  vertical-align: middle;
}
.gototop a:hover, .gototop a:active, .gototop a:focus {
  text-decoration: none;
  outline: none;
}

.fh5co-nav-toggle {
  width: 25px;
  height: 25px;
  cursor: pointer;
  text-decoration: none;
  top: 8px !important;
}
.fh5co-nav-toggle.active i::before, .fh5co-nav-toggle.active i::after {
  background: #444;
}
.fh5co-nav-toggle:hover, .fh5co-nav-toggle:focus, .fh5co-nav-toggle:active {
  outline: none;
  border-bottom: none !important;
}
.fh5co-nav-toggle i {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 2px;
  color: #252525;
  font: bold 14px/.4 Helvetica;
  text-transform: uppercase;
  text-indent: -55px;
  background: #252525;
  transition: all .2s ease-out;
}
.fh5co-nav-toggle i::before, .fh5co-nav-toggle i::after {
  content: '';
  width: 25px;
  height: 2px;
  background: #252525;
  position: absolute;
  left: 0;
  transition: all .2s ease-out;
}
.fh5co-nav-toggle.fh5co-nav-white > i {
  color: #fff;
  background: #000;
}
.fh5co-nav-toggle.fh5co-nav-white > i::before, .fh5co-nav-toggle.fh5co-nav-white > i::after {
  background: #000;
}

.fh5co-nav-toggle i::before {
  top: -7px;
}

.fh5co-nav-toggle i::after {
  bottom: -7px;
}

.fh5co-nav-toggle:hover i::before {
  top: -10px;
}

.fh5co-nav-toggle:hover i::after {
  bottom: -10px;
}

.fh5co-nav-toggle.active i {
  background: transparent;
}

.fh5co-nav-toggle.active i::before {
  top: 0;
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
  background: #fff;
}

.fh5co-nav-toggle.active i::after {
  bottom: 0;
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
  background: #fff;
}

.fh5co-nav-toggle {
  position: absolute;
  right: 0px;
  top: 65px;
  z-index: 21;
  padding: 6px 0 0 0;
  display: block;
  margin: 0 auto;
  display: none;
  height: 44px;
  width: 44px;
  z-index: 2001;
  border-bottom: none !important;
}
@media screen and (max-width: 768px) {
  .fh5co-nav-toggle {
    display: block;
  }
}

.btn {
  margin-right: 4px;
  margin-bottom: 4px;
  font-family: "Josefin Slab", Arial, serif;
  font-size: 16px;
  font-weight: 400;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding: 8px 20px;
}
.btn.btn-md {
  padding: 8px 20px !important;
}
.btn.btn-lg {
  padding: 18px 36px !important;
}
.btn:hover, .btn:active, .btn:focus {
  box-shadow: none !important;
  outline: none !important;
}

.btn-primary {
  background: #1d2f3b;
  color: #fff;
  border: 2px solid #1d2f3b;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  background: #253d4c !important;
  border-color: #253d4c !important;
}
.btn-primary.btn-outline {
  background: transparent;
  color: #1d2f3b;
  border: 2px solid #1d2f3b;
}
.btn-primary.btn-outline:hover, .btn-primary.btn-outline:focus, .btn-primary.btn-outline:active {
  background: #1d2f3b;
  color: #fff;
}

.btn-success {
  background: #5cb85c;
  color: #fff;
  border: 2px solid #5cb85c;
}
.btn-success:hover, .btn-success:focus, .btn-success:active {
  background: #4cae4c !important;
  border-color: #4cae4c !important;
}
.btn-success.btn-outline {
  background: transparent;
  color: #5cb85c;
  border: 2px solid #5cb85c;
}
.btn-success.btn-outline:hover, .btn-success.btn-outline:focus, .btn-success.btn-outline:active {
  background: #5cb85c;
  color: #fff;
}

.btn-info {
  background: #5bc0de;
  color: #fff;
  border: 2px solid #5bc0de;
}
.btn-info:hover, .btn-info:focus, .btn-info:active {
  background: #46b8da !important;
  border-color: #46b8da !important;
}
.btn-info.btn-outline {
  background: transparent;
  color: #5bc0de;
  border: 2px solid #5bc0de;
}
.btn-info.btn-outline:hover, .btn-info.btn-outline:focus, .btn-info.btn-outline:active {
  background: #5bc0de;
  color: #fff;
}

.btn-warning {
  background: #f0ad4e;
  color: #fff;
  border: 2px solid #f0ad4e;
}
.btn-warning:hover, .btn-warning:focus, .btn-warning:active {
  background: #eea236 !important;
  border-color: #eea236 !important;
}
.btn-warning.btn-outline {
  background: transparent;
  color: #f0ad4e;
  border: 2px solid #f0ad4e;
}
.btn-warning.btn-outline:hover, .btn-warning.btn-outline:focus, .btn-warning.btn-outline:active {
  background: #f0ad4e;
  color: #fff;
}

.btn-danger {
  background: #d9534f;
  color: #fff;
  border: 2px solid #d9534f;
}
.btn-danger:hover, .btn-danger:focus, .btn-danger:active {
  background: #d43f3a !important;
  border-color: #d43f3a !important;
}
.btn-danger.btn-outline {
  background: transparent;
  color: #d9534f;
  border: 2px solid #d9534f;
}
.btn-danger.btn-outline:hover, .btn-danger.btn-outline:focus, .btn-danger.btn-outline:active {
  background: #d9534f;
  color: #fff;
}

.btn-outline {
  background: none;
  border: 2px solid gray;
  font-size: 16px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.btn-outline:hover, .btn-outline:focus, .btn-outline:active {
  box-shadow: none;
}

.btn.with-arrow {
  position: relative;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.btn.with-arrow i {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -8px;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}
.btn.with-arrow:hover {
  padding-right: 50px;
}
.btn.with-arrow:hover i {
  color: #fff;
  right: 18px;
  visibility: visible;
  opacity: 1;
}

.form-control {
  box-shadow: none;
  background: transparent;
  border: 2px solid rgba(0, 0, 0, 0.1);
  height: 54px;
  font-size: 18px;
  font-weight: 300;
}
.form-control:active, .form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: #1d2f3b;
}

.row-pb-md {
  padding-bottom: 4em !important;
}

.row-pb-sm {
  padding-bottom: 2em !important;
}

.col-padded-right {
  padding-right: 2em !important;
}
@media screen and (max-width: 768px) {
  .col-padded-right {
    padding-right: 15px !important;
  }
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.fh5co-loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url(../images/loader.gif) center no-repeat #fff;
}

.js .animate-box {
  opacity: 0;
}

@media (min-width: 992px) {
  .design-box-mh {
    max-height: 319px;
  }
}
@media (min-width: 1200px) {
  .design-box-mh {
    max-height: 391px;
  }
}

.d-flex {
  display: flex;
}

.social-network-row-mb {
  margin-bottom: 53px;
}

.social-network-box-image-1 {
  width: auto;
  height: 90px;
}
@media (min-width: 768px) {
  .social-network-box-image-1 {
    width: auto;
    height: 160px;
  }
}

.social-network-box-image-2 {
  width: auto;
  height: 89px;
}
@media (min-width: 768px) {
  .social-network-box-image-2 {
    width: auto;
    height: 159px;
  }
}

.fs-16 {
  font-size: 16px;
}

.fst-italic {
  font-style: italic;
}

.gallery-collage-area {
  --bs-gutter-x: 1rem;
  display: flex;
  flex-wrap: wrap;
}

.gallery-collage-align-right {
  text-align: right !important; 
  flex: 0 0 auto; 
  width: 50%; 
  max-width: 100%; 
  padding-right: calc(var(--bs-gutter-x)/2); 
  padding-left: calc(var(--bs-gutter-x)/2); 
  margin-top: var(--bs-gutter-y);
}

.gallery-collage-align-flex {
  flex: 0 0 auto; 
  width: 50%; 
  max-width: 100%; 
  padding-right: calc(var(--bs-gutter-x)/2); 
  padding-left:calc(var(--bs-gutter-x)/2); 
  margin-top: var(--bs-gutter-y);
}

.gallery-collage-image-wd-100 {
  max-width: 100%; 
  height: auto; 
  background-color: #fff !important; 
  width: 100% !important; 
  margin-bottom: 1rem !important; 
  animation: fadeIn 2s;
}

.gallery-collage-image-wd-50 {
  max-width: 100%; 
  height: auto; 
  background-color: #fff !important; 
  width: 50% !important; 
  animation: fadeIn 2s;
}

.gallery-collage-image-wd-50-mb {
  max-width: 100%; 
  height: auto; 
  background-color: #fff !important;
  width: 50% !important; 
  margin-bottom: 1rem !important; 
  animation: fadeIn 2s;
}

.gallery-collage-image-wd-100-mgb-5 {
  max-width: 100%; 
  height: auto; 
  background-color: #fff !important; 
  width: 100% !important; 
  margin-bottom: 5rem !important; 
  animation: fadeIn 2s;
}

.fw-501 {
  font-weight: 501;
}

.mr-03 {
  margin-right: 0.3em;
}

.pl-1 {
  padding-left: 1px;
}

.pr-1 {
  padding-right: 1px;
}

.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img-autosize-adjust {
  max-width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .img-principal-display {
    display: flex;
  }
}

.img-principal-autosize-adjust {
  max-width: 112%;
  height: auto;
}
@media (min-width: 1200px) {
  .img-principal-autosize-adjust {
    max-width: none;
  }
}

.img-principal-lh {
  line-height: 0px;
}
@media (min-width: 992px) {
  .img-principal-lh {
    line-height: 11.7px;
  }
}
@media (min-width: 1200px) {
  .img-principal-lh {
    line-height: normal;
  }
}

.justify-content-center {
  justify-content: center;
}

.aling-items-center {
  align-items: center;
}

.home-video {
  text-align: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.book-iframe {
  width: 100%;
  height: 660px;
}

.story-book-decorative-text {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (min-width: 540px) and (max-width: 762px) {
  .featured-box-sm-h {
    height: 407px !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .featured-box-sm-h {
    height: 500px !important;
  }
  .left-menu-custom {
    width: 63%;
  }
  .right-menu-custom {
    width: 37%;
  }
}

@media only screen and (max-width: 762px) {
  .home-video {
    text-align: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 991px) {
  .story-book-decorative-text {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 35%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

